const React = require('react');
const Layout = require('./src/components/sections/Layout/Layout.tsx').default;

exports.onClientEntry = () => {
  const hubspot = document.createElement('script');
  hubspot.id = 'hubSpotChat';
  hubspot.src = '//js.hsforms.net/forms/v2.js';
  document.body.appendChild(hubspot);

  const salesloft = document.createElement('script');
  salesloft.innerHTML = `
    (function(i, s, o, g, r, a, m) {
        i['SLScoutObject'] = r;
        i[r] = i[r] || function() {
          (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date();
        a = s.createElement(o),
          m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m)
      })(window, document, 'script', 'https://scout-cdn.salesloft.com/sl.js','slscout');
      slscout(['init', 'eyJhbGciOiJIUzI1NiJ9.eyJ0IjoxMTM1NDh9.MzgwO2YP0ePQ0O5IJoPcI5w6r2rsWWQ5B25hXTNSRyg']);
  `;
  document.body.appendChild(salesloft);
};

exports.wrapPageElement = ({element, props}) => {
  const jquery = document.createElement('script');
  jquery.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js';

  const scriptCaliberBody = document.createElement('script');
  scriptCaliberBody.src = 'https://cdn.calibermind.com/js/identifyEmail.latest.js';
  
  const pageUseForm = props?.pageContext?.useForm;
  if (props?.data?.scripts?.publicURL) {
    const scriptCaliberHead = document.createElement('script');
    scriptCaliberHead.src = props.data.scripts.publicURL;
    document.head.appendChild(scriptCaliberHead);
  } 

  if(pageUseForm) {
    document.body.appendChild(jquery);
    document.body.appendChild(scriptCaliberBody);
  }

  return <Layout {...props}>{element}</Layout>
};

exports.onRouteUpdate = ({location, prevLocation}) => {
  if(location?.state)
    location.state.prevPath = prevLocation?.pathname;
};